<template>
    <div id="language" class="language" @click="toggler()">
        <BaseIconTitle
            :title="tokens.newDesign.language"
            :material-icon="'language'"
        />

        <BaseSelectModalBox
            @click.stop
            :position="selectPosition"
            :placeholder="rawTokens[langActive] ? rawTokens[langActive]._name : ''"
            :options="Object.entries(languages).map(([key, value]) => ({
                    key: value,
                    title: rawTokens[value] ? rawTokens[value]._name : '',
                }))
            "
            v-model="lang"
            :state="state"
            @update="changeLang"
        >
            <template #option="{option}">
                <div class="flag fs small-flag" :class="'flag-'+option.key"></div>
                <div class="name">{{ option.title }}</div>
            </template>
        </BaseSelectModalBox>
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import toggler from "@/new-design/shared/mixins/toggler"
import BaseSelectModalBox from "@/new-design/shared/ui/Inputs/BaseSelectModalBox.vue"
import {mapGetters} from "vuex"
import {LANG} from "@/store/mutations.type"

export default {
    name: "PlatformLanguage",
    components: {BaseSelectModalBox, BaseIconTitle},
    mixins: [toggler],
    props: {
      selectPosition: {
          type: String,
      }
    },
    computed: {
        lang: {
            get() {
                return this.langActive
            },
            set(value) {
                this.$store.commit('SET_LANG_ACTIVE', value)
            }
        },
        ...mapGetters(['languages', 'rawTokens', 'langActive', 'modals', 'activePage','tokens'])
    },
    methods: {
        changeLang(lang) {
            this.$store.commit(LANG, lang)
        }
    },
    watch: {
        state(val) {
            const app =  document.getElementById('app')

            if (val && (this.modals.menuSettings || this.activePage === 'settings')) {
                const isMobile = app.classList.contains('mobile')

                if (!isMobile) {
                    return
                }

                app.classList.add('hidden')

                setTimeout(() => {
                    const languageElement = document.getElementById('language')
                    const languagePanel = document.getElementById('language-panel')
                    if (languageElement) {
                        languageElement.style.position = 'static'
                    }

                    if (languagePanel) {
                        languagePanel.style.position = 'static'
                    }
                }, 1)
            } else {
                app.classList.remove('hidden')
            }
        },
    },
}
</script>

<style scoped>
.language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}
</style>
