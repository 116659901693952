<script>
export default {
    name: "BaseButtonSmall",
    props: {
        buttonText: {
            type: String,
        },
        icon: {
            type: String,
        },
        buttonStyle: {
            type: String,
        },
        load: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<template>
    <button class="button-small" :class="buttonStyle">
        <div v-if="!load" class="state-layer">
            <span class="label__medium">{{ buttonText }}</span>
            <span class="icon" v-if="icon">
                <i class="material-symbols-outlined">{{ icon }}</i>
            </span>
        </div>
        <span v-else class="dots-loader">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </span>
    </button>
</template>

<style scoped>
.button-small {
    cursor: pointer;
    border-radius: var(--Corner-Extra-small);
}

.state-layer {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 14px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon i {
    font-size: 16px;
}

.button-small.outlined-primary {
    color: var(--System-Primary);
    border: 1px solid var(--System-Outline);
}

.button-small.outlined-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:focus-visible {
    border: 1px solid var(--System-Primary);
}

.button-small.outlined-primary:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.button-small.outlined-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-small.outlined-primary:disabled {
    color: var(--System-OnSurface);
    border: 1px solid var(--Statelayers-onsurface-opacity12);
}

.button-small.outlined-primary {
    color: var(--System-Primary);
}

.button-small.outlined-primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.outlined-primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.button-small.outlined-primary:disabled {
    color: var(--System-OnSurface);
}

.button-small.tonal-secondary {
    color: var(--System-Primary);
    background: var(--System-Surface3);
}

.button-small.tonal-secondary:hover {
    background: var(--Statelayers-primary-opacity08);
}

.button-small.tonal-secondary:focus-visible {
    background: var(--Statelayers-surface-opacity12);
}

.button-small.tonal-secondary:active {
    background: var(--Statelayers-primary-opacity16);
}

.button-small.tonal-secondary:disabled {
    color: var(--System-OnSurface);
}

.button-small.tonal-secondary:disabled .state-layer {
    background: var(--Statelayers-onsurface-opacity12);
}

.button-small.secondary-fixed {
    border: 1px solid var(--System-PrimaryFixedDim);
    color: var(--System-SecondaryFixed);
}

.button-small.secondary-fixed:hover .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity08);
}

.button-small.secondary-fixed:focus-visible .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity12);
}

.button-small.secondary-fixed:active .state-layer {
    background: var(--Statelayers-secondaryfixed-opacity16);
}

.button-small.secondary-fixed:disabled {
    border: 1px solid var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}

/* Container for the dots */
.dots-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    width: 50px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
}

/* Dot Style */
.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFF;
    animation: bounce 1.5s ease-in-out infinite;
}

/* Different delay for each dot */
.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

/* Bouncing animation (move bottom to top) */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px); /* Adjust the distance of the bounce */
    }
}
</style>
