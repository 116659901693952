<template>
    <div class="inner">
        <div class="svg-container">
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="paint0_linear" x1="0.0" x2="1.9" y1="2.1" y2="1.8">
                        <stop offset="0.25" stop-color="#FF004D" />
                        <stop offset="0.75" stop-color="#00FFC5" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="0.0" x2="1.9" y1="2.1" y2="1.8">
                        <stop offset="0.25" stop-color="#FF004D" />
                        <stop offset="0.75" stop-color="#00FFC5" />
                    </linearGradient>
                </defs>
                <g class="layer">
                    <path clip-rule="evenodd"
                        d="m146.65,77.97l7.9,-0.17c-6.19,-71.44 -58.69,-77.98 -76.92,-77.98c-42.8,0 -77.5,34.03 -77.5,76c0,0.84 0.01,1.67 0.04,2.5l8.98,-0.08c-0.01,-0.58 -0.02,-1.16 -0.02,-1.74c0,-37.35 30.64,-67.68 68.5,-67.68c15.91,0 68.13,12.39 69.02,69.15zm8.44,0.35c0,0 0,0 0,0l0,0z"
                        fill="url(#paint0_linear)" fill-opacity="0.5" fill-rule="evenodd" id="svg_1" />
                    <path
                        d="m155.5,76c0,0.84 -0.01,1.67 -0.04,2.5l-5.98,0c0,-0.23 0.01,-0.45 0.01,-0.68c0.01,-0.38 0.01,-0.76 0.01,-1.14c0,-39.04 -32.01,-70.68 -71.5,-70.68c-39.49,0 -71.5,31.64 -71.5,70.68c0,0.61 0.01,1.21 0.02,1.82l-5.98,0c-0.03,-0.83 -0.04,-1.66 -0.04,-2.5c0,-41.97 34.7,-76 77.5,-76c42.8,0 77.5,34.03 77.5,76z"
                        fill="#3F454F" id="svg_2" />
                    <path clip-rule="evenodd"
                        d="m78,6c16.59,0 69.75,12.19 71.51,72.67l6.16,0.06c-1.38,-68.66 -59.43,-78.72 -77.66,-78.72c-42.8,0 -77.5,34.03 -77.5,76c0,0.84 0.01,1.67 0.04,2.5l5.98,0c-0.01,-0.61 -0.02,-1.21 -0.02,-1.82c0,-39.04 32.01,-70.68 71.5,-70.68z"
                        fill="url(#paint1_linear)" fill-rule="evenodd" id="svg_3" />
                    <circle cx="78" cy="78" fill="#9A9DAB" id="svg_4" r="3" />
                    <path d="m109.6,34.54l-30.12,44.86l-3.24,-2.35l33.36,-42.51z" fill="#9A9DAB" id="arrow"
                        :style="{ transform: `rotate(${meterData - 126}deg)` }" />
                </g>
            </svg>
        </div>
        <div class="main-text body__large">{{ mainText }}</div>
        <div class="item-text strong-sell label__medium">{{ tokens.newDesign.strongSell }}</div>
        <div class="item-text sell label__medium">{{ tokens.newDesign.sell }}</div>
        <div class="item-text neutral label__medium">{{ tokens.newDesign.newutral }}</div>
        <div class="item-text buy label__medium">{{ tokens.newDesign.buy }}</div>
        <div class="item-text strong-buy label__medium">{{ tokens.newDesign.stringBuy }}</div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "BaseChartFearGreedIndex",
    props: {
        data: {
            type: Number,
            default: 0,
            required: true
        }
    },
    computed: {
        ...mapGetters(['tokens']),
        meterData() {
            let data = this.data * 1.8;
            if (data < 0) {
                data = 0
            } else if (data > 180) {
                data = 180
            }
            return data;
        },
        mainText(){
            let mainText;
            if (this.meterData < 31) {
                mainText = 'Strong Sell';
            } else if (this.meterData > 30 && this.meterData < 71) {
                mainText = 'Sell';
            } else if (this.meterData > 70 && this.meterData < 121) {
                mainText = 'Neutral';
            } else if (this.meterData > 120 && this.meterData < 151) {
                mainText = 'Buy';
            } else if (this.meterData <= 180) {
                mainText = 'Strong Buy';
            }
            return mainText
        }
    }
}
</script>

<style scoped>
.inner {
    padding: 35px 0;
    min-width: 320px;
    max-width: 320px;
    min-height: 145px;
    max-height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.svg-container {
    width: 160px;
    height: 81px;
    overflow: hidden;
    zoom: .9;
}

#arrow {
    transform-origin: 78px 78px;
    transition: all .3s ease;
}

.main-text {
    color: var(--System-On-Surface-Variant, #9A9DAB);
    padding-top: 10px;
    transform: translateX(-2px);
    position: absolute;
    bottom: 0px;
    left:49%;
    transform: translateX(-50%);
}

.item-text {
    color: var(--System-OutlineVariant, #3F454F);
    letter-spacing: 0.5px;
    position: absolute;
}

.neutral {
    top: 12px;
    left:49%;
    transform: translateX(-50%);
}

.sell {
    top: 40px;
    left: 75px;
}

.buy {
    top: 40px;
    right: 75px;
}

.strong-sell {
    bottom: 35px;
    left: 15px;
}

.strong-buy {
    bottom: 35px;
    right: 15px;
}

@media (max-width: 768px) {
    .inner {
        margin-top: -10px;
    }
}
</style>
