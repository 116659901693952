<template>
    <div class="modal-wrapper"
         v-if="modals.symbols"
         @click.stop="hideModal">
        <div class="modal ElevationDark__2" @click.stop>
            <div class="title">
                <span class="title__medium">{{tokens.newDesign.addSymbol}}</span>
                <BaseIconButton @click.native.stop="hideModal">
                    <template #icon>
                        <i class="close material-symbols-outlined">close</i>
                    </template>
                </BaseIconButton>
            </div>
            <SymbolsGroup/>
        </div>
    </div>
</template>

<script>
import SymbolsGroup from "@/new-design/widgets/SymbolsGroup.vue"
import {mapGetters} from "vuex"
import {MODAL} from "@/store/mutations.type"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue";

export default {
    name: "SymbolsModal",
    components: {BaseIconButton, SymbolsGroup},
    computed: {
        ...mapGetters(['modals','tokens'])
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                symbols: false
            })
        },
    }
}
</script>

<style scoped>
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 360px;
    transform: translate(-50%, -50%);
    height: 420px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    flex-direction: column;
    background: var(--System-Surface3);
    border-radius: var(--Corner);
}

.title {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
}
</style>
