<script>
export default {
    name: "BaseInLineIconButton",
    props: {
        buttonStyle: {
            type: String,
        }
    }
}
</script>

<template>
    <button class="inline-icon-button" :class="buttonStyle">
        <span class="state-layer">
            <slot name="icon"/>
        </span>
    </button>
</template>

<style scoped>
.inline-icon-button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inline-icon-button .state-layer {
    padding: 2px;
    border-radius: var(--Corner-Extra-small);
    height: 100%;
    width: 100%;
    display: inline-block;
}

.inline-icon-button path {
    fill: var(--System-OnSurface);
    color: var(--System-OnSurface);
}

.inline-icon-button:hover .state-layer {
    background-color: var(--Statelayers-onsurface-opacity08);
}

.inline-icon-button:focus .state-layer {
    background-color: var(--Statelayers-onsurface-opacity12);
}

.inline-icon-button:active .state-layer {
    background-color: var(--Statelayers-onsurface-opacity16);
}

.inline-icon-button.primary path {
    fill: var(--System-Primary);
}

.inline-icon-button.primary {
    color: var(--System-Primary);
}

.inline-icon-button.primary:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.inline-icon-button.primary:focus .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.inline-icon-button.primary:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.inline-icon-button:disabled .state-layer,
.inline-icon-button.primary:disabled .state-layer {
    opacity: .38;
}
</style>
