<template>
    <button class="button-asset">
        <span class="state-layer">
            <span class="label__medium">{{text}}</span>
            <span v-if="$slots['icon']" class="icon material-symbols-outlined">
                <slot name="icon"/>
            </span>
        </span>
    </button>
</template>

<script>
export default {
    name: "BaseButtonAsset",
    props: {
        text: {
            type: String,
        }
    }
}
</script>

<style scoped>
.button-asset {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface5);
    cursor: pointer;
    color: var(--System-Primary);
}

.button-asset .state-layer {
    display: flex;
    gap: 4px;
    padding: 2px 6px;
    align-items: center;
}

.state-layer .icon {
    font-size: 12px;
}

.state-layer:hover {
    background: var(--Statelayers-primary-opacity08);
}

.state-layer:focus-visible {
    background: var(--Statelayers-primary-opacity12);
}

.state-layer:active {
    background: var(--Statelayers-primary-opacity16);
}
</style>
