<template>
    <button class="close-button">
        <span class="state-layer">
            <span class="time-container body__small">
                {{tokens.ordersPane.closeTime}}: <span class="time">{{getExpirationMessage(order.expiration)}}</span>
            </span>
            <BaseInLineIconButton
                :button-style="'primary'"
                @click.native.stop="reset(order)"
            >
                <template #icon>
                    <i class="material-symbols-outlined">cancel</i>
                </template>
            </BaseInLineIconButton>
        </span>
    </button>
</template>

<script>
import BaseInLineIconButton from "@/new-design/shared/ui/buttons/BaseInLineIconButton.vue";
import {mapGetters} from "vuex";

export default {
    name: "TimeCloseButton",
    components: {BaseInLineIconButton},
    props: {
        order: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters(['tokens'])
    },
    methods: {
        reset(order) {
            this.$emit('reset', order)
        },
        getExpirationMessage(expiration) {
            if (!expiration) {
                return
            }

            const date = new Date(expiration * 1000)

            const day = String(date.getUTCDate()).padStart(2, '0')
            const month = String(date.getUTCMonth() + 1).padStart(2, '0')
            const year = String(date.getUTCFullYear()).slice(2)
            const seconds = String(date.getUTCSeconds()).padStart(2, '0')

            const offset = date.getTimezoneOffset()
            const offsetHours = Math.floor(Math.abs(offset) / 60)
            const offsetMinutes = Math.abs(offset) % 60
            const sign = offset > 0 ? '-' : '+'

            const adjustedHours = String(date.getUTCHours() + (sign === '+' ? offsetHours : -offsetHours)).padStart(2, '0')
            const adjustedMinutes = String(date.getUTCMinutes() + (sign === '+' ? offsetMinutes : -offsetMinutes)).padStart(2, '0')

            return `${day}/${month}/${year}, ${adjustedHours}:${adjustedMinutes}:${seconds}`
        },
    }
}
</script>

<style scoped>
.close-button {
    border-radius: var(--Corner-Extra-small);
    background-color: var(--System-Surface3);
    margin-bottom: 16px;
    cursor: pointer;
}

.state-layer {
    padding: 6px 8px;
    display: grid;
    grid-template-columns: 1fr max-content;
}

.close-button:hover .state-layer {
    background-color: var(--Statelayers-primary-opacity08);
}

.close-button:active .state-layer {
    background-color: var(--Statelayers-primary-opacity16);
}

.time-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--System-OnSurfaceVariant);
}

.time {
    color: var(--System-OnSurface);
}

i {
    font-size: 12px;
    cursor: pointer;
}
</style>
