<template>
    <div class="notification ElevationDark__5" :class="{verification: notification.type === 'verification'}">
        <div v-if="notification.icon" class="icon">
            <span v-html="notification.icon"></span>
        </div>
        <div class="content" :class="{support: notification.type === 'support'}"
             @click="notification.type === 'support' ? showCabinetPage('support') : ''">
            <div class="title label__large">{{ notification.title }}</div>
            <div class="text body__small">
                <span v-if="notification.type !== 'order' && notification.type !== 'order_open'">{{ notification.text }}</span>
                <div
                    v-if="notification.type === 'order' || notification.type === 'order_open'"
                    class="order"
                >
                    <div class="info">
                        <span
                              class="symbol"
                              :class="'symbol-'+notification.data.symbol.toUpperCase()"/>
                        <span class="name body__medium">{{notification.data.symbol.toUpperCase()}}</span>
                    </div>
                    <div class="order-info">
                        <span class="profit label__large"
                              :class="{hide: notification.type !== 'order' && notification.type !== 'transaction'}">{{
                                notification.profitText
                            }}</span>
                        <span class="percent" :class="notification.textClass">{{notification.text }}</span>
                    </div>
                </div>
                <div v-if="notification.chips" class="notification-chips">
                    <BaseButtonAsset
                        v-for="(chip, index) in notification.chips"
                        :key="index"
                        :text="chip.text"
                        @click.native="goto(chip.link)"
                    >
                        <template #icon>
                            open_in_new
                        </template>
                    </BaseButtonAsset>
                </div>
            </div>
        </div>
        <i class="material-icons close" v-on:click="hideNotification(notification.id)">close</i>
    </div>
</template>

<script>
import {currencySign} from "@/common/helpers"
import {mapGetters} from "vuex"
import {MODAL, MODAL_FULLSCREEN, NOTIFICATION_REMOVE} from "@/store/mutations.type"
import BaseButtonAsset from "@/new-design/shared/ui/buttons/BaseButtonAsset.vue";

export default {
    name: "NotificationsListItem",
    components: {BaseButtonAsset},
    props: {
        notification: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        showCabinetPage(page) {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
        },
        closeHamburgerMenu() {
            setTimeout(() => {
                this.$store.commit(MODAL, {
                    hamburgerMenu: false
                })
            }, 0)
        },
        hideNotification(id) {
            this.$store.commit(NOTIFICATION_REMOVE, id)
        },
        goto(link) {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.closeHamburgerMenu()

            this.$store.commit(MODAL_FULLSCREEN, {
                page: link
            })
        },
        currencySign
    },
    computed: {
        ...mapGetters(['notifications', 'symbols', 'activeAccount', 'tokens', 'staticBase']),
    }
}
</script>

<style scoped>
.notification {
    width: 345px;
    display: flex;
    align-items: center;
    background: var(--System-Surface3);
    border: 1px solid var(--System-OutlineVariant);
    padding: 15px;
    border-radius: var(--Corner-Extra-small);
    position: relative;
    gap: 16px;
    margin-bottom: 6px;
}

.icon {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
}

.icon img {
    width: 24px;
    height: 24px;
}

.info {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px;
}

.info span.symbol {
    zoom: 0.25;
    position: relative;
}

.icon i {
    font-size: 26px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    color: var(--System-OnSurfaceVariant);
    font-weight: 500;
    margin-bottom: 6px;
}

.text {
    color: var(--System-OnSurfaceVariant);
    display: flex;
    flex-direction: column;
}

.order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.green {
    color: var(--Custom-GreenFixed);
}

.red {
    color: var(--Custom-RedFixed);
}

.order-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.name {
    color: var(--System-OnSurface);
}

.order-info .profit {
    color: var(--System-OnSurface);
    font-weight: 500;
}

.close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: var(--System-OnSurfaceVariant)
}

.content.support {
    cursor: pointer;
}

.notification.verification {
    align-items: flex-start;
}

.notification-chips {
    margin-top: 10px;
}
</style>
