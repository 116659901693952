<template>
    <div>
        <div v-if="documents.length" class="table-list">
            <UploadedDocumentItem
                v-for="document in documents"
                :document="document"
                :key="document.id" />
        </div>
        <div v-else class="table-list empty-data">
            <i class="material-symbols-outlined">find_in_page</i>
            {{tokens.newDesign.noDocument}}
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import UploadedDocumentItem from '@/new-design/entities/Verification/ui/UploadedDocumentItem.vue';

export default {
    name: "UploadedDocumentList",
    components: { UploadedDocumentItem },
    computed: {
        ...mapGetters(['documents', 'tokens']),
    }
}
</script>

<style scoped>
.table-list {
    border: 1px solid var(--System-OutlineVariant);
    border-radius: 8px;
    padding: 12px;
    min-height: 72px;
}

.table-list.empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
</style>
