<template>
    <div class="saving-page">
        <div class="left">
            <div class="page-title">
                <BasePageTitle :title="tokens.accountModal.savings" />
            </div>
            <BasePanel>
                <template #content>
                    <div class="left-inner">
                        <div class="tabs-wrapper">
                            <BaseTabs
                                :activeTab="activeTab"
                                :tabs="[tokens.newDesign.investmentDetails, tokens.newDesign.savingsAccounts]"
                                :tabStyle="{ fontSize: '14px', 'padding-inline': '16px' }"
                                :tabsWrapperStyle="{
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, max-content))',
                                    minWidth: '300px' }"
                                @updateActiveTab="activeTab = $event" />
                        </div>

                        <InvestmentDetailsTab
                            v-if="activeTab == 0"
                            :periods="periods"
                            :periodChosen="periodChosen"
                            :currencyChosen="currencyChosen"
                            @updateChosenStaking="updateChosenStaking($event)"
                            @updatePeriodChosen="updatePeriodChosen($event)"
                        />

                        <SavingsAccountsTab v-if="(activeTab == 1 && isSavingsPageOpen)"/>

                        <div class="selected-block-wrapper">
                            <div class="selected-block" :class="{ 'fixed-bottom': isMobile }">
                                <div class="selected-title label__largeprominent">{{tokens.newDesign.selected}}</div>
                                <div class="selected-info label__largeprominent">
                                    <template v-if="currencyChosen">
                                        <div class="selected-currency">{{ currencyChosen }}</div>
                                        <div class="selected-period">
                                            <span>{{ tokens.fullScreenSavings[periodChosen] }}</span>
                                            <span>{{ satkingChosen[periodChosen].toFixed(1) }}%</span>
                                        </div>
                                    </template>
                                </div>
                                <BaseButton :buttonText="tokens.newDesign.subscribe"  :buttonStyle="'filled-primary'" @click.native="changeSubscribe" />
                            </div>
                        </div>
                    </div>
                </template>
            </BasePanel>
        </div>
        <div class="right" :class="{active: isOpenSubscribe}" >
            <div class="right-title title__medium">
                <span>{{tokens.newDesign.subscribe}}</span>
                <BaseModalTitle>
                    <template #left>
                        <div class=" title__medium">{{ tokens.newDesign.subscribe }}</div>
                    </template>
                    <template #right>
                       <i class="material-symbols-outlined" @click="changeSubscribe"> close </i>
                    </template>
                </BaseModalTitle>
            </div>
            <BasePanel>
                <template #content>
                    <SubscribeBlock
                        v-if="isSavingsPageOpen"
                        :periods="periods"
                        :periodChosen="periodChosen"
                        :currencyChosen="currencyChosen"
                        :savingModalState="isOpenSubscribe"
                        @openSavingsAccountsTab="openSavingsAccountsTab"
                        @resetVars="resetVars"
                        @updatePeriodChosen="updatePeriodChosen($event)"
                    />
                </template>
            </BasePanel>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel"
import BaseTabs from "@/new-design/shared/ui/tabs/BaseTabs"
import InvestmentDetailsTab from "@/new-design/not-sorted/new/SavingsPage/InvestmentDetailsTab"
import SubscribeBlock from "@/new-design/not-sorted/new/SavingsPage/SubscribeBlock"
import SavingsAccountsTab from "@/new-design/not-sorted/new/SavingsPage/SavingsAccountsTab"
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton"
import {isMobile} from "@/common/helpers"

export default {
    name: "SavingsPage",
    components: {
        BasePageTitle,
        BasePanel,
        BaseTabs,
        InvestmentDetailsTab,
        SubscribeBlock,
        SavingsAccountsTab,
        BaseModalTitle,
        BaseButton,
    },
    data() {
        return {
            activeTab: 0,
            satkingChosen: '',
            currencyChosen: '',
            periodChosen: 'pct_0',
            isOpenSubscribe: false,
            isMobile: isMobile(),
            periods: ['pct_0', 'pct_30', 'pct_90', 'pct_180', 'pct_365'],
        }
    },
    computed: {
        ...mapGetters(['tokens','modalFullscreen','modals']),
        isSavingsPageOpen() {
            return (this.modalFullscreen.page === 'savings') && this.modals.fullscreen;
        }
    },
    methods: {
        resetVars() {
            this.satkingChosen = ''
            this.currencyChosen = ''
            this.periodChosen = 'pct_0'
        },
        changeSubscribe(){
            this.isOpenSubscribe = !this.isOpenSubscribe
        },
        updateChosenStaking(staking){
            this.currencyChosen = this.currencyChosen === staking.currency ? '' : staking.currency;
            this.satkingChosen = staking
        },
        updatePeriodChosen(e){
            this.periodChosen = e
        },
        openSavingsAccountsTab() {
            this.activeTab = 1;
            this.isOpenSubscribe = false
        }
    }
}
</script>

<style scoped>
.panel {
    padding: 0;
    border: none !important;
    height: 100%;
}

.saving-page {
    display: flex;
    min-height: 100%;
}

.page-title {
    padding-inline: 8px;
}

.left,
.right {
    width: 100%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.left {
    border-right: 1px solid var(--System-OutlineVariant);
}

.left-inner {
    max-height: calc(100dvh - 140px);
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    overflow: hidden;
}

.tabs-wrapper {
    overflow-x: auto;
    margin-bottom: 20px;
}

.right-title {
    padding: 24px 16px 16px;
    color: var(--System-OnSurfaceVariant);
    background: var(--System-Surface);
}

.right-title > div {
    display: none;
}

.selected-block-wrapper{
    display: none;
}

.banner-is-active .panel {
    height: calc(100% - 125px);
}

@media (max-width: 1023px) {
    .panel {
        height: calc(100% - 81px);
    }

    .left{
        max-width: 100%;
        border-right: none;
    }

    .selected-block-wrapper {
        min-height: 85px;
        display: block;
    }

    .selected-block {
        background: var(--System-Surface3);
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 24px 16px;
        width: 100%;
     }

    .selected-block.fixed-bottom {
        position: fixed;
        left: 0;
        bottom: 0;
    }

    .selected-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        border-radius: 4px;
        gap: 8px;
        white-space: nowrap;
        background: var(--System-Surface1);
        flex: 1 1 auto;
        min-height: 36px;
    }

    .selected-currency,
    .selected-period > span:last-child{
        color: var(--System-OnSurface);
    }

    .selected-period {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .right {
        position: absolute;
        inset: 0;
        z-index: 88888;
        background: var(--System-Surface);
        display: none;
        max-width: 100%;
    }

    .right.active {
        display: block;
    }

    .right-title {
        border-bottom: 1px solid var(--System-OutlineVariant);
        margin-bottom: 16px;
    }

    .right-title > span {
        display: none;
    }

    .right-title > div {
        display: flex;
    }

    .right-title i {
        cursor: pointer;
    }
}
</style>
