<template>
    <div class="account-transfer"
        :class="visibility ? '' : 'blur'">
            <div class="account-transfer-header"
                @click="changeVisibility">
                    <div class="title title__medium">{{ tokens.fullScreenAccounts.manageAccounts }}</div>
                    <i  class="material-symbols-outlined" :style="isOpenTransfer ? 'rotate: 180deg' : ''">keyboard_arrow_down</i>
            </div>
            <div v-if="isOpenTransfer"
                class="account-transfer-inner"
                :class="{ hide: hideTransfer }">
                    <div class="from-pad">
                        <BaseTransferSelect
                            :title="tokens.fullScreenAccounts.transferFrom"
                            :options="accountsFromOptions"
                            :value="accountsFromOptions.find((e) => e.id === transfer.from.id)"
                            @update="setOption($event, 'from')" />
                        <BaseNumberInput
                            :title="tokens.fullScreenAccounts.amount"
                            type="number" v-model="transfer.amount"
                            :placeholder="'0.0'"
                            :validateFunction="updateInput">
                                <template #icon>
                                    <div>{{ transfer.from.currency }}</div>
                                </template>
                        </BaseNumberInput>
                    </div>

                    <div v-if="!onlyOneAccount"
                        class="swap-icon"
                        @click="swapAccounts"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M21.9999 10V8H5.8249L8.3999 5.425L6.9999 4L2.70701 8.29289C2.07704 8.92286 2.52321 10 3.41411 10H21.9999Z"
                                fill="#9A9DAB" />
                            <path
                                d="M2.9999 14V16H19.1749L16.5999 18.575L17.9999 20L22.2928 15.7071C22.9228 15.0771 22.4766 14 21.5857 14H2.9999Z"
                                fill="#9A9DAB" />
                        </svg>

                    </div>

                    <div v-if="!onlyOneAccount" class="to-pad">
                        <BaseTransferSelect
                            :title="tokens.fullScreenAccounts.transferTo"
                            :options="accountsToOptions"
                            :value="accountsToOptions.find((e) => e.id === transfer.to.id) || accountsToOptions[0]"
                            @update="setOption($event, 'to')" />
                        <BaseNumberInput
                            :title="tokens.newDesign.amountDeposited"
                            type="number"
                            :placeholder="'0.0'" :readonly="true"
                            :value="Number((transfer.amount * (conversions[transfer.from.currency] / conversions[transfer.to.currency])).toFixed(6)) || ''">
                                <template #icon>
                                    <div>{{ transfer.to.currency }}</div>
                                </template>
                        </BaseNumberInput>
                    </div>
            </div>
            <div v-if="isOpenTransfer && !onlyOneAccount"
                class="account-transfer-footer">
                    <div class="currency">
                        {{ `1 ${transfer.from.currency} ≈ ${Number((conversions[transfer.from.currency] /
                            conversions[transfer.to.currency]).toFixed(6))} ${transfer.to.currency}` }}
                    </div>
                    <BaseButton :buttonText="tokens.newDesign.makeTransfer" :buttonStyle="'filled-primary'" @click.native="makeTransfer" />
            </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TRANSFER_REQUEST } from '@/store/actions.type'
import { NOTIFICATION_ERROR } from '@/store/mutations.type'
import { currencySign } from '@/common/helpers'
import BaseNumberInput from '@/new-design/shared/ui/Inputs/BaseNumberInput'
import BaseTransferSelect from '@/new-design/shared/ui/selects/BaseTransferSelect'
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton'


export default {
    name: "AccountTransfer",
    components: { BaseNumberInput, BaseTransferSelect, BaseButton },
    props: {
        visibility: {
            type: Boolean
        }
    },
    data() {
        return {
            isOpenTransfer: true,
            transfer: {
                from: {
                    product: 'cfd',
                    id: 0,
                    currency: 'USD'
                },
                to: {
                    product: 'cfd',
                    id: 0,
                    currency: 'USD'
                },
                amount: ''
            },
            hideTransfer: false,
            transferDisable: false
        }
    },
    computed: {
        ...mapGetters(['accounts', 'activeAccount', 'tokens', 'savingsAccounts', 'conversions']),
        onlyOneAccount() {
            return Object.keys(this.accounts).length === 1
        },
        accountsFromOptions() {
            let options = []

            for (let n in this.accounts) {
                options.push({
                    id: this.accounts[n].id,
                    product: this.accounts[n].product,
                    currency: this.accounts[n].currency,
                    text: this.accounts[n].product.toUpperCase() + ' ' + this.accounts[n].id + '(' + currencySign(this.accounts[n].currency) + this.accounts[n].balance.toFixed(this.accounts[n].precision ? this.accounts[n].precision : 1) + ')'
                })
            }

            return options
        },
        accountsToOptions() {
            let options = []

            for (let n in this.accounts) {
                if (this.accounts[n].id != this.transfer.from.id) {
                    options.push({
                        id: this.accounts[n].id,
                        product: this.accounts[n].product,
                        currency: this.accounts[n].currency,
                        text: this.accounts[n].product.toUpperCase() + ' ' + this.accounts[n].id + '(' + currencySign(this.accounts[n].currency) + this.accounts[n].balance.toFixed(this.accounts[n].precision ? this.accounts[n].precision : 1) + ')'
                    })
                }
            }

            return options
        },
    },
    methods: {
        changeVisibility() {
            this.isOpenTransfer = !this.isOpenTransfer
        },
        updateInput(e) {
            return e.endsWith('.') ? e.replace(/,/g, "") : Number(e.replace(/,/g, ""));
        },
        makeTransfer() {

            if(this.transferDisable) return

            if (!this.transfer.amount) {
                this.$store.commit(NOTIFICATION_ERROR, 'Incorrect amount')
                return
            }

            if (Object.keys(this.activeAccount.orders).length) {
                this.$store.commit(NOTIFICATION_ERROR, 'First need to close open orders')
                return
            }

            this.transferDisable = true
            if (this.accounts[this.transfer.from.id].balance - this.transfer.amount > 0) {
                this.$store.dispatch(TRANSFER_REQUEST, {
                    from: {
                        product: this.transfer.from.product,
                        id: this.transfer.from.id
                    },
                    to: {
                        product: this.transfer.to.product,
                        id: this.transfer.to.id
                    },
                    amount: this.transfer.amount
                })
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'Not enough money in the balance')
            }
            this.transfer.amount = ''
            setTimeout(() => {
                this.transferDisable = false
            }, 2000)
        },
        setOption(e, select) {
            if (this.transfer[select].id != e.id) {
                this.transfer[select].id = e.id;
                this.transfer[select].product = e.product;
                this.transfer[select].currency = e.currency;
                this.amount = ''
            }
        },
        swapAccounts(){
            const from = {...this.transfer.from};
            this.setOption({...this.transfer.to}, 'from')
            this.setOption(from, 'to')
        },
        currencySign
    },
    watch: {
        accounts: {
            deep: true,
            handler(accs) {
                if (!this.transfer.from.id || !this.transfer.to.id) {
                    this.hideTransfer = true
                    for (let n in accs) {
                        if (!this.transfer.from.id) {
                            this.transfer.from.id = accs[n].id
                            this.transfer.from.product = accs[n].product
                            this.transfer.from.currency = accs[n].currency


                            continue
                        }
                        if (!this.transfer.to.id) {
                            this.transfer.to.id = accs[n].id
                            this.transfer.to.product = accs[n].product
                            this.transfer.to.currency = accs[n].currency


                            if (this.hideTransfer) {
                                this.hideTransfer = false
                            }
                            break
                        }
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
.account-transfer {
    border: 1px solid var(--System-OutlineVariant);
    margin-bottom: 24px;
    border-radius: 4px;
    /* overflow: hidden; */
    transition: all .3s ease;
}

.blur {
    filter: blur(4px);
    pointer-events: none
}

.account-transfer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    cursor: pointer;
}

.account-transfer-header i {
    transition: all .3s ease;
    font-size: 22px;
}

.title {
    color: var(--System-OnSurface)
}

.account-transfer-inner {
    padding: 24px 16px;
    border-top: 1px solid var(--System-OutlineVariant);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.from-pad,
.to-pad {
    padding: 12px 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 4px;
    background: var(--System-Surface1);
    width: 100%;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.account-transfer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    margin: 0 16px;
    border-top: 1px solid var(--System-OutlineVariant);
}

.swap-icon {
    cursor: pointer;
}

@media(max-width: 1023px) {
    .account-transfer-inner{
        flex-direction: column;
    }
}
</style>
