<template>
    <div class="accounts-page">
        <BasePageTitle :title="tokens.fullScreen.accounts">
            <template #icon>
                <i class="material-symbols-outlined" @click="changeVisibility">
                    {{ isVisibleAccountCards ? 'visibility' : 'visibility_off' }}
                </i>
            </template>
        </BasePageTitle>

        <BasePanel v-if="Object.keys(accounts).length > 1">
            <template #content>
                <AccountTransfer :visibility="isVisibleAccountCards" />
            </template>
        </BasePanel>

        <BasePanel>
            <template #content>
                <AccountCard v-for="account in accounts" :key="account.id" :account="account" :visibility="isVisibleAccountCards" />
            </template>
        </BasePanel>
      </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel"
import AccountCard from "@/new-design/shared/ui/account-type/AccountCard"
import AccountTransfer from "@/new-design/features/Accounts/ui/AccountTransfer"

export default {
    name: 'AccountsPage',
    components: {
        BasePageTitle,
        BasePanel,
        AccountCard,
        AccountTransfer
    },
    data() {
        return {
            isVisibleAccountCards: true,
        }
    },
    computed: {
        ...mapGetters(['accounts', 'tokens','tokens']),
    },
    methods: {
        changeVisibility() {
            this.isVisibleAccountCards = !this.isVisibleAccountCards
        },
    },
}
</script>

<style scoped>
.panel {
    padding: 0;
    border: none !important;
}
i {
    cursor: pointer;
}
</style>
