<template>
    <div class="select-wrapper">
        <BaseSelectButton
            :options="options"
            :value="active"
            @update="val => selectIsOpen = val"
        >
            <template #title>
                {{options[active]}}
            </template>
        </BaseSelectButton>
        <BaseMenuList v-if="selectIsOpen">
            <template #content v-if="selectIsOpen">
                <BaseMenuListItem
                    v-for="(value, key) in options"
                    :key="key"
                    :class="{ active: active === key }"
                    :tag="'li'"
                    @click.native="updateSelect(key)"
                >
                    <template #content>
                        {{ value }}
                    </template>
                </BaseMenuListItem>
            </template>
        </BaseMenuList>
    </div>
</template>

<script>
import BaseSelectButton from "@/new-design/shared/ui/selects/BaseSelectButton.vue"
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"

export default {
    name: "PositionsSelect",
    components: {BaseMenuListItem, BaseMenuList, BaseSelectButton},
    props: {
        options: {
            type: Object
        },
        active: {
            type: [String, Boolean],
        }
    },
    data() {
        return {
            selectIsOpen: false
        }
    },
    methods: {
        updateSelect(item) {
            this.$emit('update', item)
        }
    }
}
</script>

<style scoped>
.select-wrapper {
    position: relative;
}
</style>
