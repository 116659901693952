<template>
    <div class="order-vals body__small">
        <div class="order-val">
            <span class="title">{{ tokens.orderMenu.contractSize }}:</span>
            <span class="digit">{{ contractSize.toLocaleString() }}</span>
        </div>
        <div class="order-val">
            <span class="title">{{ tokens.orderMenu.position }}:</span>
            <span class="digit">{{ orderVals.position.toLocaleString() }}</span>
        </div>
        <div class="order-val">
            <span class="title">{{ tokens.orderMenu.margin }}:</span>
            <span class="digit" :class="{red: orderVals.margin > parseFloat(orderVals.freeMargin)}">
                {{ currencySign(activeAccount.currency) }}{{ orderVals.margin.toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision }) }}
            </span>
        </div>
        <div class="order-val">
            <span class="title">{{ tokens.orderMenu.freeMargin }}:</span>
            <span class="digit" :class="{ red: orderVals.freeMargin < 0 }">
                {{ currencySign(activeAccount.currency) + orderVals.freeMargin.toLocaleString(undefined, {
                    minimumFractionDigits: activeAccount.precision,
                    maximumFractionDigits: activeAccount.precision
                }) }}
            </span>
        </div>
        <div class="order-val" :class="{ hide: pendingPrice }">
            <span class="title">
                {{ tokens.orderMenu.spread }}:
            </span>
            <span class="digit">{{ spread }}</span>
        </div>
        <div class="order-val" v-show="showLeverage == 1">
            <span class="title">
                {{ tokens.orderMenu.leverage }}:
            </span>
            <span class="digit">1:{{ computedLeverage }}</span>
        </div>
        <!-- <div class="order-val"><span class="title">Trend Strength:</span><span class="digit">{{ trend }}</span></div>-->
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign} from "@/common/helpers"
import {SHOW_LEVERAGE} from "@/common/config"



export default {
    name: 'OrderPanelVals',
    props: ['chart', 'contractSize', 'orderVals', 'pendingPrice'],
    data(){
        return {
            showLeverage: SHOW_LEVERAGE,

        }
    },
    computed: {
        ...mapGetters(['activeAccount', 'tokens','symbols']),
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                equity: 0,
                margin: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision)) : 0
            }

            for(let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n])
            }

            b.equity = b.balance + b.credit + b.profit
            return b
        },
        currentSym() {
            return this.chart.symbol || ''
        },
        spread() {
            if (!this.currentSym || !this.symbols[this.currentSym]) {
                return 0
            }

            return Number(this.symbols[this.currentSym].quote.ask - this.symbols[this.currentSym].quote.bid).toFixed(this.symbols[this.currentSym].precision)
        },
        computedLeverage() {
            if(this.symbols[this.currentSym]) {
                return this.symbols[this.currentSym].leverage_default || this.activeAccount.leverage
            } else {
                return 1
            }
        },

    },
    methods: {
        currencySign
    }

}
</script>

<style scoped>

.order-vals {
    text-align: right; /* for desktop alignment */
    border-radius:  4px;
    border: 1px solid var(--System-Surface5);
    padding: 14px;
    min-height: 166px;
}

.order-val {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-val:last-child {
    margin: 0;
}

.order-val .title {
    color: var(--System-OnSurfaceVariant);
}

.order-val .digit {
    color: var(--Custom-GreenFixed);
}

.order-val .digit.red {
    color: var(--Custom-RedFixed);
}
</style>
