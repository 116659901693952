<template>
    <div class="item">
        <div class="slot-wrapper">
            <slot name="icon" />
        </div>
        <div class="info">
            <h4 class="title label__medium">{{ title }}</h4>
            <span class="value label__medium" :title="data" :class="color">{{ data }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountInfoListItem",
    props: {
        title: {
            type: String
        },
        data: {
            type: [String, Number]
        },
        color: {
            type: String,
            default: ''
        },
    }
}
</script>

<style scoped>
.item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    padding: 12px;
    gap: 8px;
    width: 100%;
    overflow: hidden;
}

.slot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Statelayers-primary-opacity08);
    padding: 4px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px 12px;
    flex-wrap: wrap;
    width: 100%;
}

.title {
    color: var(--System-OnSurfaceVariant);
}

.value {
    color: var(--System-OnSurface);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.red {
    color: var(--Custom-RedFixed)
}

.green {
    color: var(--Custom-GreenFixed)
}

@media(max-width: 1023px) {
    .item {
        padding: 12px 10px;
        gap: 6px;
    }
}
</style>
