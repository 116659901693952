<template>
    <div @click.stop="TrFromSelect = false; TrToSelect = false">
        <div class="fullscreen-page-title" :class="{hide: hideTransfer}">
            {{ tokens.fullScreen.accounts.toUpperCase() }}
        </div>
        <div class="form-title" :class="{hide: hideTransfer}">
            {{ tokens.fullScreenAccounts.manageAccounts.toUpperCase() }}
        </div>
        <div class="row" :class="{hide: hideTransfer}">
            <div class="col">
                <div class="form-row">
                    <label class="fs">{{ tokens.fullScreenAccounts.transferFrom }}</label>
                    <select class="form-control fs" v-model="transferModel.from" :class="{activeSelect: TrFromSelect}" @click.stop="TrFromSelect = !TrFromSelect">
                        <option v-for="account in accounts"
                                :key="'from-cfd-'+account.id"
                                :value="account.product+'-'+account.id"
                        >{{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1)+')' }}</option>
                        <option v-for="account in savingsAccounts"
                                :key="'from-savings-'+account.id"
                                :value="account.product+'-'+account.id"
                        >{{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1)+')' }}</option>
                    </select>
                </div>
                <div class="form-row">
                    <label class="fs">{{ tokens.fullScreenAccounts.amount }}</label>
                    <input class="form-control fs" type="number" v-model="transfer.amount" />
                </div>
            </div>
            <div class="col">
                <div class="form-row">
                    <label class="fs">{{ tokens.fullScreenAccounts.transferTo }}</label>
                    <select class="form-control fs" v-model="transferModel.to" :class="{activeSelect: TrToSelect}" @click.stop="TrToSelect = !TrToSelect">
                        <option v-for="account in accounts"
                                :key="'to-cfd-'+account.id"
                                :value="account.product+'-'+account.id"
                                :class="{hide: transfer.from.id === account.id && transfer.from.product === account.product}"
                        >{{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1)+')' }}</option>
                        <option v-for="account in savingsAccounts"
                                :key="'to-savings-'+account.id"
                                :value="account.product+'-'+account.id"
                                :class="{hide: transfer.from.id === account.id && transfer.from.product === account.product}"
                        >{{ account.product.toUpperCase()+' '+account.id+' ('+currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1)+')' }}</option>
                    </select>
                </div>
                <div class="form-row">
                    <label style="height: 14px">&nbsp;</label>
                    <button class="fs-normal" 
                            :class="{'spin-loader': transferDisable}" 
                            :disabled="transferDisable"
                            @click="makeTransfer">
                                {{ tokens.fullScreenAccounts.makeTransfer }}
                    </button>
                </div>
            </div>
        </div>
        <div class="form-title">
            {{ tokens.fullScreenAccounts.yourAccounts }}
        </div>
        <div class="table-cont">
            <table class="cabinet fs">
                <thead>
                <tr>
                    <th class="fs">{{ tokens.fullScreenAccounts.account.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenAccounts.currency.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenAccounts.balance.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenAccounts.credit.toUpperCase() }}</th>
                    <th class="fs"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="account in accounts"
                    :key="account.id"
                    class="fs"
                >
                    <td>{{ account.product.toUpperCase()+' '+account.id }}</td>
                    <td>{{ account.currency }}</td>
                    <td>{{ currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1) }}</td>
                    <td>{{ currencySign(account.currency)+account.credit.toFixed(account.precision ? account.precision : 1) }}</td>
                    <td><button class="fs-small" :class="{hide: activeAccount.id === account.id}" @click="makeActive(account.id)">{{ tokens.fullScreenAccounts.makeActive }}</button><span class="active" :class="{hide: activeAccount.id !== account.id}">{{ tokens.fullScreenAccounts.active }}</span> </td>
                </tr>
                <tr
                    v-for="account in savingsAccounts"
                    :key="account.product+'-'+account.id"
                >
                    <td>{{ account.product.toUpperCase()+' '+account.id }}</td>
                    <td>{{ account.currency }}</td>
                    <td>{{ currencySign(account.currency)+account.balance.toFixed(account.precision ? account.precision : 1) }}</td>
                    <td> - </td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign, localStorage} from '@/common/helpers'
import {ACCOUNT_ACTIVE, NOTIFICATION_ERROR} from '@/store/mutations.type'
import {TRANSFER_REQUEST} from '@/store/actions.type'
export default {
    name: 'Accounts',
    data() {
        return {
            TrFromSelect:false,
            TrToSelect: false,
            transfer: {
                from: {
                    product: 'cfd',
                    id: 0
                },
                to: {
                    product: 'cfd',
                    id: 0
                },
                amount: ''
            },
            transferModel: {
                from: '',
                to: ''
            },
            hideTransfer: true,
            transferDisable: false,
        }
    },
    methods: {
        makeActive(id) {
            localStorage.setItem('activeAccount', id)
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        makeTransfer() {
            if (!this.transfer.amount) {
                this.$store.commit(NOTIFICATION_ERROR, 'Incorrect amount')
                return
            }

            if (Object.keys(this.activeAccount.orders).length) {
                this.$store.commit(NOTIFICATION_ERROR, 'First need to close open orders')
                return
            }

            this.transferDisable = true
            if(this.accounts[this.transfer.from.id].balance - this.transfer.amount > 0){
                this.$store.dispatch(TRANSFER_REQUEST, {
                    from: {
                        product: this.transfer.from.product,
                        id: this.transfer.from.id
                    },
                    to: {
                        product: this.transfer.to.product,
                        id: this.transfer.to.id
                    },
                    amount: this.transfer.amount
                }).finally(() => {
                    this.transferDisable = false
                })
            }else {
                this.$store.commit(NOTIFICATION_ERROR, 'Not enough money in the balance')
            }
        },
        currencySign
    },
    computed: {
        ...mapGetters(['accounts', 'activeAccount', 'savingsAccounts', 'tokens'])
    },
    watch: {
        accounts: {
            deep: true,
            handler(accs) {
                if (!this.transferModel.from || !this.transferModel.to) {
                    this.hideTransfer = true
                    for(let n in accs) {
                        if (!this.transferModel.from) {
                            this.transfer.from.id = accs[n].id
                            this.transfer.from.product = accs[n].product

                            this.transferModel.from = accs[n].product+'-'+accs[n].id

                            continue
                        }
                        if (!this.transferModel.to) {
                            this.transfer.to.id = accs[n].id
                            this.transfer.to.product = accs[n].product

                            this.transferModel.to = accs[n].product+'-'+accs[n].id

                            if (this.hideTransfer) {
                                this.hideTransfer = false
                            }
                            break
                        }
                    }
                }
            }
        },
        savingsAccounts: {
            deep: true,
            handler(accs) {
                if (!this.transferModel.from || !this.transferModel.to) {
                    this.hideTransfer = true
                    for(let n in accs) {
                        if (!this.transferModel.from) {
                            this.transfer.from.id = accs[n].id
                            this.transfer.from.product = accs[n].product

                            this.transferModel.from = accs[n].product+'-'+accs[n].id

                            continue
                        }
                        if (!this.transferModel.to) {
                            this.transfer.to.id = accs[n].id
                            this.transfer.to.product = accs[n].product

                            this.transferModel.to = accs[n].product+'-'+accs[n].id

                            if (this.hideTransfer) {
                                this.hideTransfer = false
                            }
                            break
                        }
                    }
                }
            }
        },
        transferModel: {
            deep: true,
            handler(val) {
                if (val.from === val.to) {
                    let newTo = {
                        id: 0,
                        product: 'cfd'
                    }
                    for(let n in this.accounts) {
                        if (this.accounts[n].id != val.from.id && this.accounts[n].product != val.from.product) {
                            newTo = this.accounts[n]
                            break
                        }
                    }

                    if (!newTo.id) {
                        for(let n in this.savingsAccounts) {
                            if (this.savingsAccounts[n].id != val.from.id && this.savingsAccounts[n].product != val.from.product) {
                                newTo = this.savingsAccounts[n]
                                break
                            }
                        }
                    }

                    this.transfer.to.id = newTo.id
                    this.transfer.to.product = newTo.product

                    this.transferModel.to = newTo.product+'-'+newTo.id
                }

                let toks = {
                    from: val.from.split('-'),
                    to: val.to.split('-')
                }

                this.transfer.from.product = toks.from[0]
                this.transfer.from.id = toks.from[1]

                this.transfer.to.product = toks.to[0]
                this.transfer.to.id = toks.to[1]
            }
        }
    }
}
</script>

<style scoped>
    .form-title {
        margin-bottom: 20px;
    }

    .row {
        flex-direction: row !important;
        align-items: stretch;
    }

    .col {
        flex: 1 1 40%;
        justify-content: space-between;
    }

    .col:last-child {
        margin-left: 30px;
    }

    input, select {
        width: 100%;
        font-size: 1.2em;
    }

    .active {
        color: var(--green);
        width: 50%;
        display: inline-block;
        text-align: center;
        border: 1px solid #3BA163;
        font-size: 20px;
        padding: 8px 12px;
        border-radius: 10px;
    }

    .aspin-design .active {
        color: var(--white-to-black);
        border: 1px solid #246CF9;
        width: 100%;
        height: 45px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    button.fs-normal.spin-loader{
        overflow: hidden;
        position: relative;
        cursor: default;
    }

    button.fs-normal.spin-loader:after{
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(0,0,0,0.8);
    }
    
    button.fs-normal.spin-loader:before{
        content: "";
        position: absolute;
        width: 2em;
        height: 2em;
        z-index: 5;
        padding: .4em;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #ffffff;
        --_m:
            conic-gradient(#0000 10%, #000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
        animation: l3 1s infinite linear;
    }

    @keyframes l3 {
        to {
            transform: rotate(1turn)
        }
    }

    @keyframes l2 {
        to {
            transform: rotate(1turn)
        }
    }

    @media (min-width: 1000px) {
        .active {
            border: 0.1vw solid #3BA163;
            font-size: 1vw;
            padding: 0.1vw 1vw;
            border-radius: 0.2vw;
        }

        .aspin-design .active {
            border: .1vw solid #246CF9;
        }
    }

    @media (max-width: 1378px) {
        .active{
            width: 40%;
        }
    }

    @media (max-width: 1000px) {
        .fs-small{
            width: 100%;
        }

        .active{
            width: 100%;
        }
        .col{
            flex: 1 1 100%;
        }

        .col:last-child{
            margin-left: 0;
        }
    }
</style>
