<template>
    <div class="status-bar">
        <div class="row">
            <span class="title body__small">{{ tokens.statusBar.balance }}:</span>
            <span class="content">{{
                currencySign(activeAccount.currency) + (activeAccount.balance > 0 ? activeAccount.balance :
                    0).toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
            }}</span>
        </div>
        <div class="row">
            <span class="title body__small">{{ tokens.statusBar.credit }}:</span>
            <span class="content">{{
                currencySign(activeAccount.currency) + (activeAccount.credit > 0 ? activeAccount.credit :
                    0).toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
            }}</span>
        </div>

        <div class="more-info">
            <div class="row" :class="{ hide: (margin === 0) || (isMobile() && !statusBarItemsShowMobile) }">
                <span class="title body__small">{{ tokens.statusBar.equity }}:</span>
                <span class="content">{{
                    currencySign(activeAccount.currency) + (activeAccount.balance + activeAccount.credit +
                        pnl).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        })
                }}</span>
            </div>
            <div class="row" :class="{ hide: (margin === 0) || (isMobile() && !statusBarItemsShowMobile) }">
                <span class="title body__small">{{ tokens.statusBar.margin }}:</span>
                <span class="content">{{
                    currencySign(activeAccount.currency) + margin.toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
                }}</span>
            </div>
            <div class="row" :class="{ hide: (margin === 0) || (isMobile() && !statusBarItemsShowMobile) }">
                <span class="title body__small">{{ tokens.statusBar.marginLevel }}:</span>
                <span class="content">{{
                    activeAccount.balance + activeAccount.credit + pnl !== 0 && margin ? (100 * (activeAccount.balance +
                        activeAccount.credit + pnl) / margin).toFixed(2) + '%' : '-'
                    }}</span>
            </div>
            <div class="row" :class="{ hide: (margin === 0) || (isMobile()) }">
                <span class="title body__small">{{ tokens.statusBar.freeMargin }}:</span>
                <span class="content">{{
                    margin !== 0 ? currencySign(activeAccount.currency) + (activeAccount.balance + activeAccount.credit
                        + pnl - margin).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) : '-'
                }}</span>
            </div>
            <div class="row" :class="{ hide: (margin === 0) || (isMobile()) }">
                <span class="title body__small">{{ tokens.statusBar.pnl }}:</span>
                <span class="content" :class="pnl < 0 ? 'red' : (pnl > 0 ? 'green' : '')">{{
                    currencySign(activeAccount.currency) + pnl.toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
                }}</span>
            </div>
        </div>

        <div class="right" v-show="ordersPane">
            <span class="title body__small">{{ tokens.statusBar.profitTotal }}:</span>
            <span class="content" :class="profitTotal < 0 ? 'red' : (profitTotal > 0 ? 'green' : '')">{{
                currencySign(activeAccount.currency) + profitTotal.toLocaleString(undefined, {
                    minimumFractionDigits: activeAccount.precision,
                    maximumFractionDigits: activeAccount.precision
                })
            }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { currencySign, isMobile } from "@/common/helpers"
import { STATUSBAR_ITEMS_SHOW_MOBILE } from "@/common/config"

export default {
    name: "StatusBar",
    data() {
        return {
            profitTotal: 0,
            statusBarItemsShowMobile: STATUSBAR_ITEMS_SHOW_MOBILE
        }
    },
    methods: {
        currencySign, isMobile,
    },
    computed: {
        ...mapGetters(['orders', 'symbols', 'activeAccount', 'tokens', 'ordersPane', 'historyTableIsActive','conversions']),
        pnl() {
            let pnl = 0

            for (let i in this.orders) {
                let order = this.orders[i]

                if (order.type > 1 || !this.symbols[order.symbol]) {
                    continue
                }
                pnl += order.point * (order.type === 0 ? this.symbols[order.symbol].quote.bid - order.price_open : order.price_open - this.symbols[order.symbol].quote.ask) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission
            }

            return pnl
        },
        margin() {
            let margin = 0
            let marginBySymbol = {}

            for (let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]
                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }
                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }
            }
            for (let n in marginBySymbol) {
                margin += Math.abs(marginBySymbol[n]) * (1 / this.conversions[this.activeAccount.currency])
            }

            return margin
        }
    },
    watch: {
        activeAccount: {
            deep: true,
            handler(newVal) {
                newVal.profitTotal ? this.profitTotal = newVal.profitTotal : this.profitTotal = 0
            }
        }
    }
}
</script>

<style scoped>
.status-bar {
    padding: 0 10px;
    flex: 1 1 auto;
    max-height: 40px;
    min-height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    overflow-y: auto;
    border-top: 1px solid var(--System-OutlineVariant);
    border-left: 1px solid var(--System-OutlineVariant);
    border-right: 1px solid var(--System-OutlineVariant);
}

.status-bar .row {
    flex-wrap: wrap;
}

.title {
    color: var(--System-Outline);
    line-height: 1;
}

.more-info {
    display: flex;
}

.right {
    flex-grow: 1;
    padding-right: 12px;
    align-self: center;
    text-align: right;
}

.row {
    flex-wrap: nowrap;
}

span {
    margin-right: 5px;
    white-space: nowrap;
}

span.content {
    margin-right: 20px;
    color: var(--System-OnSurface);
    font-size: 11px;
}

span.content.red {
    color: var(--Custom-RedFixed)
}

span.content.green {
    color: var(--Custom-GreenFixed)
}

@media (max-width: 1023px) {
    .row, .right {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: start;
        gap: 2px;
    }

    .right {
        display: flex;
        max-width: max-content;
        margin-left: auto;
    }

    span {
        margin-right: 15px;
    }
}
</style>
