<template>
    <div class="update-document-item">
        <div class="update-document-label label__mediumprominent">{{ document.text }}</div>
        <div
            v-if="!isUploaded"
            class="update-document-drop"
            @drop.prevent="(e) => addFile(e)"
            @dragover.prevent
            @dragenter="over(true)"
            @dragleave="over(false)"
            @dragover="over(true)">
            <i class="material-symbols-outlined">upload_file</i>
            <div class="label__medium">
                <div class="update-document-button">
                    {{ tokens.newDesign.clickToUpload }}
                </div>
                <span>{{tokens.newDesign.orDrag}}</span>
            </div>
            <input type="file" @change="documentSelected($event, document.type)" />
        </div>
        <div v-else class="update-document-drop uploaded">
            <div class="label__small">
                <span>{{tokens.newDesign.fileAdded}}</span>
                <span>{{tokens.newDesign.theVerificationStatus}}</span>
            </div>
        </div>
        <div class="update-document-info body__extrasmall">{{tokens.newDesign.fileFormat}}</div>
    </div>
</template>

<script>
import { DOCUMENT_UPLOAD } from "@/store/actions.type"
import {mapGetters} from "vuex"

export default {
    name: "UpdateDocument",
    props: {
        document: {
            type: Object
        },
        idx: {
            type: Number
        }
    },
    computed: {
        ...mapGetters(['tokens']),
        isUploaded() {
            return (this.document.status === 0 || this.document.status === 1)
        }
    },
    methods: {
        addFile(e) {
            this.document.over = false

            if (!e.dataTransfer.files || [0, 1].indexOf(this.document.status) !== -1) {
                return
            }

            let formData = new FormData()

            formData.append('file', e.dataTransfer.files[0])
            formData.append('data', JSON.stringify({ type: this.document.type }))

            this.$store.dispatch(DOCUMENT_UPLOAD, formData)
        },
        over(val) {
            this.document.over = val
        },
        documentSelected(e, type) {
            let data = new FormData()

            data.append('file', e.target.files[0])
            data.append('data', JSON.stringify({
                type
            }))

            this.$store.dispatch(DOCUMENT_UPLOAD, data)

            e.target.value = ''
        },
    }
}
</script>

<style scoped>
.update-document-item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    gap: 4px;
    min-height: 110px;
}

.update-document-label {
    color: var(--System-OnSurface)
}

.update-document-drop {
    border: 1px dashed gray;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    min-height: 80px;
    position: relative;
    overflow: hidden;
    transition: background-color .1s ease-in-out;
}

.update-document-drop:hover {
    background-color: var(--Statelayers-onsurfacevariant-opacity08);
}

.update-document-drop:focus-within {
    background-color: var(--Statelayers-onsurfacevariant-opacity12);
}

.update-document-drop:active {
    background-color: var(--Statelayers-onsurfacevariant-opacity16);
}

.update-document-drop.uploaded {
    border: 1px solid var(--System-Outline);
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 6px;
    text-align: start;
    min-height: 80px;

}

.update-document-drop.uploaded span {
    display: block;
    cursor: auto;
    color: var(--System-OnSurfaceVariant);
}

.update-document-drop.uploaded span:first-child {
    color: var(--System-OnSurface);
    margin-bottom: 4px;
}

.update-document-drop .update-document-button {
    color: var(--System-Primary);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    margin-right: 5px;
}

.update-document-drop > input {
    position: absolute;
    width: 100%;
    height: 150%;
    top: -50%;
    left: 0%;
    cursor: pointer;
    font-size: 16px;
    opacity: 0;
}
</style>
