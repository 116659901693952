<template>
    <div v-if="isMobile" class="footer-buttons">
        <BaseTradingButton
            :buttonText="sessionsState[currentSym] ? tokens.orderMenu.buy.toUpperCase() : tokens.newDesign.closed"
            :buttonClass="'buy' + (!sessionsState[currentSym] ? ' close' : '') + (tradeDisabled ? ' disabled' : '')"
            @click.native="openOrderMenuModal(0)">
                <template #sum>
                    <span class="price">
                        <span>
                            {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                            symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym].quote &&
                                symbols[currentSym]?.quote.ask ?
                            symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(0, -2) : 0 :
                            ''}}
                        </span>
                        <span>
                            {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                            symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ?
                            symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(-2) : 0 : ''}}
                        </span>
                    </span>
                </template>
        </BaseTradingButton>
        <BaseTradingButton
            :buttonText="sessionsState[currentSym] ? tokens.orderMenu.sell.toUpperCase() : tokens.newDesign.closed"
            :buttonClass="'sell' + (!sessionsState[currentSym] ? ' close' : '') + (tradeDisabled ? ' disabled' : '')"
            @click.native="openOrderMenuModal(1)">
                <template #sum>
                    <span class="price">
                        <span>
                            {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                            symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote &&
                                symbols[currentSym]?.quote.ask ?
                            symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(0, -2) : 0 : ''
                            }}
                        </span>
                        <span>
                            {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                            symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ?
                            symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(-2) : 0 : ''
                            }}
                        </span>
                    </span>
                </template>
        </BaseTradingButton>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MODAL, MODAL_ORDER_MENU_GRID_WIDE } from '@/store/mutations.type'
import { isMobile } from '@/common/helpers';
import BaseTradingButton from '@/new-design/shared/ui/buttons/BaseTradingButton';

export default {
    components: {
        BaseTradingButton
    },
    data() {
        return {
            isMobile: isMobile(),
        }
    },
    computed: {
        ...mapGetters(['tokens','symbols', 'sessionsState','charts', 'chartActive','tradeDisabled']),
        currentSym() {
            return this.symbol.symbol || ''
        },
        symbol() {
            let symbol = {
                alias: ''
            }

            if (!this.chartActive || !this.charts[this.chartActive]) {
                return symbol
            }
            return this.symbols[this.charts[this.chartActive].symbol] || symbol
        },
    },
    methods: {
        openOrderMenuModal(tab) {
            this.$store.commit(MODAL, {
                orderMenuGridWideModal: true,
            })
            this.$store.commit(MODAL_ORDER_MENU_GRID_WIDE, { tab })
        }
    },
}
</script>

<style scoped>
.footer-buttons {
    padding: 16px;
    border-top: 1px solid var(--System-OutlineVariant);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    place-content: center;
    height: 80px;
}

.footer-buttons button {
    min-height: 50px;
}
</style>
