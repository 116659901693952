<template>
    <div class="header" :class="{'aspin-design': aspinDesign}">
        <div class="row left-row">
            <div class="logo">
                <a :href="!logoRedirectOff ? website : '#'"
                   :target="!logoRedirectOff ? '_blank' : ''"
                   :style="{cursor: !logoRedirectOff ? '' : 'default', 'pointer-events': hideLogo ? 'none' : 'auto'}">
                    <img :src="logoComputed" :style="{height: logo.height, opacity: hideLogo ? 0 : 1}" :class="{hide: !logoComputed}" @error="errorHandler"/>
                    <span :class="{hide: logoComputed}"><span class="brand">{{ tokens.header.cfd }}</span> {{ tokens.header.platform }}</span>
                </a>
            </div>
            <div class="layout nomobile" v-on:click="showGridsModal" :class="{hide: multigrid !== '1'}">
                <i class="material-icons">dashboard</i>
            </div>
            <div class="active-symbols">
                <div class="item"
                     v-for="(chart, i) in charts"
                     :key="i"
                     :class="{
                         active: chart.id === chartActive,
                         enabled: enabledList.indexOf(chart.id) !== -1,
                         inprofit: orderData[chart.symbol] && orderData[chart.symbol].profit > 0,
                         inloss: orderData[chart.symbol] && orderData[chart.symbol].profit < 0
                     }"
                     v-on:click="makeActive(chart.id)"
                >

                    <div class="symbol" :class="assetSymbol(chart.symbol)" />
<!--                    <img :src="staticBase+chart.symbol.toUpperCase()+'.png'"/>-->
                    <div class="col nomobile">
                        <span class="sym">{{symbols[chart.symbol] ? symbols[chart.symbol].alias : ''}}</span>
                        <span :class="{
                            profit: orderData[chart.symbol],
                            green: orderData[chart.symbol] && orderData[chart.symbol].profit > 0,
                            red: orderData[chart.symbol] && orderData[chart.symbol].profit < 0
                        }">{{orderData[chart.symbol] ? (orderData[chart.symbol].profit > 0 ? '+' : (0 > orderData[chart.symbol].profit ? '-' : ''))+currencySign(activeAccount.currency)+Math.abs(orderData[chart.symbol].profit).toFixed(activeAccount.precision) : tokens.markets[symbols[chart.symbol] ? symbols[chart.symbol].market : 'forex'].toLowerCase()}}</span>
                    </div>
                    <i class="material-icons" v-on:click.stop="hideSymbol(chart.id)">close</i>
                </div>
            </div>
            <div class="layout">
                <i class="material-icons" v-on:click="showSymbolsModal">add</i>
            </div>
        </div>
        <div class="row right-row">
            <div class="mobile-menu" v-on:click="toggleModalMenu">
                <i class="material-icons">menu</i>
            </div>
            <div v-if="robotAi" :class="{'is-active': robotIsActive}" class="robotai deposit" v-on:click="activateRobot()">
                <i class="material-icons">smart_toy</i>
                <span class="nomobile">{{ !robotIsActive ? "Activate robot (AI)" : "Deactivate robot (AI)"}}</span>
            </div>
            <div v-if="user.enable_autotrader && !hideTradingBot" :class="{'is-active': autotrader}" class="robotai deposit" @click="openAutoTraderModal()">
                <i class="material-icons">smart_toy</i>
                <span class="nomobile">{{ tokens.autoTrader.title }}</span>
            </div>
            <div class="deposit" v-on:click="showCabinet('deposit')">
                <i class="material-icons">payments</i>
                <span class="nomobile">{{ tokens.header.deposit }}</span>
            </div>
            <div class="balance"
            :style="{color: activeAccount.demo ? 'var(--brand)' : 'var(--green-button)'}" v-on:click="showBalanceModal">
                <div class="col align-end">
                    <div v-if="disableAccountNameUppercase" class="acc-type">
                        {{ (activeAccount.alias && activeAccount.alias) || 
                            (activeAccount.account_name  && activeAccount.account_name != 'MAIN' ?  
                                activeAccount.account_name : activeAccount.group === 'main' ? 
                                    'STANDARD' : activeAccount.group) }} 
                        {{ tokens.balanceModal.account }}</div>
                    <div v-else class="acc-type">
                        {{ (activeAccount.alias && activeAccount.alias.toUpperCase()) || 
                            (activeAccount.account_name  && activeAccount.account_name != 'MAIN' ?  
                                activeAccount.account_name : activeAccount.group === 'main' ? 
                                    'STANDARD' : activeAccount.group.toUpperCase()) }} 
                        {{ tokens.balanceModal.account }}</div>
                    <div class="val">{{ currencySign(activeAccount.currency) + (activeAccount.balance > 0 ? activeAccount.balance : 0).toLocaleString(undefined, { minimumFractionDigits: activeAccount.precision, maximumFractionDigits: activeAccount.precision}) }}</div>
                </div>
                <i class="material-icons"> {{ modals.balance ? 'arrow_drop_up' : 'arrow_drop_down' }}</i>
            </div>
            <div class="plate" v-if="!isMobile">
                <img :src="plate">
            </div>
            <div class="row">
                <div class="avatar" v-on:click="showAccountModal">
                    <div class="circle">
                        <i class="material-icons" :class="{hide: user.avatar}">person</i>
                        <img :src="user.avatar ? apiBase+'/avatar/'+user.avatar : ''" :class="{hide: !user.avatar}"/>
                    </div>
<!--                    <i class="material-icons">arrow_drop_down</i>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {
        REST_ENDPOINT,
        PLATES_FOLDER,
        TRADINGVIEW_THEME,
        ROBOT_AI,
        ASPIN_DESIGN,
        HIDE_LOGO, HIDE_TRADING_BOT,
        BRAND,
        WHITE_ASPIN_THEME,
        LOGO_REDIRECT_OFF,
        DISABLE_ACCOUNT_NAME_UPPERCASE
    } from "@/common/config";
    import {currencySign, isMobile} from "@/common/helpers"
    import {
        CHART_ACTIVE,
        MODAL,
        MODAL_FULLSCREEN,
        MODAL_SYMBOLS, SET_ROBOT_AI,
        SYMBOL_HIDE
    } from '@/store/mutations.type'
    import {LOGO, MULTIGRID, WEBSITE} from "@/common/config"

    export default {
        name: "Header",
        mounted() {
            setInterval(() => {
                this.time = Math.floor(Date.now()/1000)
            }, 1000)

            document.addEventListener('keydown', e => {
                if(e.key === "Escape") {
                    this.hideAllModals()
                }
            })
        },
        data() {
            return {
                platesFolder: PLATES_FOLDER,
                apiBase: REST_ENDPOINT,
                website: WEBSITE,
                tradingViewTheme: TRADINGVIEW_THEME,
                time: Math.floor(Date.now()/1000),
                logo: LOGO,
                multigrid: MULTIGRID,
                isMobile: isMobile(),
                robotAi: ROBOT_AI,
                aspinDesign: ASPIN_DESIGN,
                hideLogo: HIDE_LOGO,
                autotrader: false,
                hideTradingBot: HIDE_TRADING_BOT,
                brand: BRAND,
                whiteAspinTheme: WHITE_ASPIN_THEME,
                logoRedirectOff: LOGO_REDIRECT_OFF,
                disableAccountNameUppercase: DISABLE_ACCOUNT_NAME_UPPERCASE
            }
        },
        methods: {
            currencySign,
            test(){
                console.log('test')
            },
            hideAllModals() {
                let allModalsFalseObject = {
                    sideMenu: true,
                    menu: this.modals.menu,
                }
                for(let n in this.modals) {
                    if(n === 'sideMenu' || n === 'menu' ) {
                        continue
                    }
                    allModalsFalseObject[n] = false
                }
                this.$store.commit(MODAL, allModalsFalseObject)
            },
            showSymbolsModal() {
                this.$store.commit(MODAL, {
                    symbols: true
                })
                this.$store.commit(MODAL_SYMBOLS, {
                    action: 'add',
                    symbolActive: null
                })
            },
            showAccountModal() {
                this.$store.commit(MODAL, {
                    fullscreen: true
                })

                this.$store.commit(MODAL,{
                    hamburgerMenu: false
                })
            },
            showBalanceModal() {
                this.$store.commit(MODAL, {
                    balance: true
                })
            },
            showGridsModal() {
                this.$store.commit(MODAL, {
                    grids: true
                })
            },
            hideSymbol(id) {
                this.$store.commit(SYMBOL_HIDE, id)
            },
            makeActive(id) {
                if (id === this.chartActive) {
                    let gridId = undefined

                    for(let n in this.gridCharts) {
                        if (this.gridCharts[n] === id) {
                            gridId = n
                            break
                        }
                    }

                    this.$store.commit(MODAL, {
                        symbols: true
                    })
                    this.$store.commit(MODAL_SYMBOLS, {
                        action: 'update',
                        id,
                        gridId
                    })
                    return
                }

                this.$store.commit(CHART_ACTIVE, id)
            },
            toggleModalMenu(){
                this.$store.commit(MODAL, this.modals.hamburgerMenu ? {
                    hamburgerMenu: false
                    } : {
                    hamburgerMenu: true
                    })
            },
            showCabinet(page) {
                this.$store.commit(MODAL, {
                    fullscreen: true,
                })
                this.$store.commit(MODAL_FULLSCREEN, {
                    page
                })
            },
            activateRobot() {
                this.$store.commit(SET_ROBOT_AI, !this.robotIsActive)
            },
            openAutoTraderModal() {
                this.$store.commit(MODAL, {
                    autoTrader: true
                })
            },
            assetSymbol(symbol) {
                if (this.brand === 'cryptoalgoritm') {
                    switch (symbol) {
                        case 'SUGAR':
                            return 'symbol-CAPITALSUGAR'
                        case 'DIS':
                            return 'symbol-CAPITALSUGAR'
                        default:
                            return 'symbol-' + symbol.toUpperCase().replace('.', '-')
                    }
                } else {
                    return 'symbol-' + symbol.toUpperCase().replace('.', '-')
                }
            },
            errorHandler(){
                this.logo.url = ''
            }
        },
        computed: {
            ...mapGetters(['charts', 'symbols', 'activeAccount', 'chartActive', 'gridCharts', 'user', 'themeColors', 'tokens', 'modals','robotIsActive','autoTraderConfig','staticBase','staticBaseDomain','lightTheme',]),
            orderData() {
                let data = {}

                for(let n in this.activeAccount.orders) {
                    let ord = this.activeAccount.orders[n]

                    if (data[ord.symbol] === undefined) {
                        data[ord.symbol] = {
                            expiration: 0,
                            profit: 0,
                            completed: 0,
                            total: 0,
                            left: 0
                        }
                    }

                    if (this.symbols[ord.symbol] === undefined) {
                        continue
                    }

                        data[ord.symbol].profit += ord.type < 2 ? (ord.point * (ord.type ? ord.price_open - this.symbols[ord.symbol].quote.ask : this.symbols[ord.symbol].quote.bid - ord.price_open) * Math.pow(10, this.symbols[ord.symbol].precision) + ord.swap + ord.commission) : 0
                }

                return data
            },
            enabledList() {
                let list = []

                for(let n in this.gridCharts) {
                    if (this.gridCharts[n] !== undefined) {
                        list.push(this.gridCharts[n])
                    }
                }

                return list
            },
            plate() {
                let metal = ['insurance','beginner', 'student', 'basic', 'bronze', 'bronze_plus', 'silver', 'silver_plus', 'gold', 'platinum', 'platinum_plus', 'vip', 'top', 'diamond','islamic','business','enterprise','regular','arbitrage','basicml', 'shift','custom','professional','brilliant','diamonds','individual','longterm','royalclub'].includes(this.activeAccount.group) ? this.activeAccount.group : 'main'
                return this.staticBase + this.platesFolder + '/' + metal + '_plate.png'
            },

            logoComputed() {
                let url = this.logo.url

                if (this.staticBaseDomain !== 'co') {
                    const parts = url.split('/')
                    parts[2] = parts[2].replace('co', this.staticBaseDomain)
                    url = parts.join('/')
                }

                if(this.whiteAspinTheme){
                    return this.lightTheme ? url.replace(/white/, 'black') : url
                }else{
                    return this.tradingViewTheme === 'Light' ? url.replace(/white/, 'black') : url
                }
            }
        }
    }
</script>

<style scoped>
    .header {
        flex: 0 0 80px;
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid var(--border);
        justify-content: space-between;
        background: var(--background-dark);
    }

    .left-row {
        overflow: hidden;
        flex: 1 1 auto;
        flex-wrap: nowrap;
    }

    .right-row {
        display: flex;
        flex: 0 1 450px;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .logo {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .logo span {
        font-size: 24px;
        font-weight: 800;
    }

    .logo img {
        max-height: 50px;
        max-width: 200px;
    }

    .logo a {
        text-decoration: none;
    }

    .layout {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border: 1px solid var(--border);
        border-radius: 5px;
        margin: 0 15px;
        cursor: pointer;
        transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
    }

    .layout:hover {
        color: white;
        background-color: var(--background-light);
    }
    .light-theme .layout:hover {
        color: var(--white-to-black);
        background-color: var(--aspin-color-hover);
    }

    .layout i {
        font-size: 36px;
    }

    .active-symbols {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        flex-wrap: nowrap;
        margin-left: 20px;
    }

    .active-symbols .item {
        border: 1px solid var(--border);
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        height: 50px;
        display: flex;
        align-items: center;
        transition: background-color ease-in-out 0.3s;
        padding: 10px 20px;
        font-weight: 600;
        position: relative;
        margin-right: 10px;
        min-width: 135px;
    }

    .active-symbols .item.enabled:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--white);
    }

    .active-symbols .item.active:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--brand);
    }

    .active-symbols .item:last-child {
        margin-right: 0;
    }

    .active-symbols .item.inprofit {
        background: var(--background-dark-mid);
        background: linear-gradient(180deg, rgba(43,171,64,0.3) 0%, rgba(55,64,84,0.5) 50%);
    }

    .active-symbols .item.inloss {
        background: var(--background-dark-mid);
        background: linear-gradient(180deg, rgba(221,73,49,0.3) 0%, rgba(55,64,84,0.5) 50%);
    }
    .light-theme .aspin-design .active-symbols .item.inprofit {
        background: linear-gradient(180deg, rgba(95, 227, 117, 0.3) 0%, rgba(215, 225, 248, 0.5) 50%);
    }
    .light-theme .aspin-design .active-symbols .item.inloss {
        background: linear-gradient(180deg, rgba(247, 109, 88, 0.3) 0%, rgba(215, 225, 248, 0.5) 50%);
    }

    .active-symbols .item img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
    }

    .active-symbols .symbol {
        zoom: 0.390625;
        margin-right: 25px;
    }

    @-moz-document url-prefix() {
        .active-symbols .symbol {
            -moz-transform: scale(0.390625);
            -moz-transform-origin: 50% 50%;
            position: absolute;
            left: 0;
        }

        .active-symbols .item .col {
            margin-left: 35px;
        }
    }

    .active-symbols .item .sym {
        color: var(--white-to-black);
        margin-bottom: 3px;
    }

    .active-symbols .item i {
        position: absolute;
        top: 2px;
        left: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .active-symbols .item:hover {
        background-color: var(--background-light);
    }

    .aspin-design .active-symbols .item:hover {
        background-color: var(--aspin-background-active);
    }

    .robotai {
        font-size: 14px;
        margin-right: 10px;
        padding: 0;
        width: max-content;
    }

    .robotai.is-active {
        color: var(--white);
        background-color: var(--green);
    }

    .avatar {
        cursor: pointer;
        transition: color ease-in-out 0.3s;
        display: flex;
        align-items: center;
    }

    .avatar .circle {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: var(--background);
        transition: background-color ease-in-out 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid var(--background);
    }

    .light-theme .avatar .circle {
        border-color: var(--green-button);
    }

    .avatar .circle img {
        height: 50px;
        width: 50px;
    }

    .avatar:hover {
        color: var(--white)
    }

    .avatar:hover .circle {
        background-color: var(--background-light);
        border-color: var(--background);
    }

    .balance {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        margin: 0 20px;
        min-width: 120px;
        white-space: nowrap;
    }

    .balance .val {
        font-size: 24px;
        font-weight: 800;
    }

    .deposit {
        border: 1px solid var(--green-button);
        color: var(--green-button);
        transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;
        font-size: 20px;
        font-weight: 400;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
        border-radius: 5px;
        cursor: pointer;
    }

    .deposit i {
        margin-right: 15px;
    }

    .deposit span {
        white-space: nowrap;
    }

    .deposit:hover {
        color: var(--white);
        background-color: var(--green-button);
    }

    .timer {
        position: absolute;
        top: -2px;
        left: 20px;
        color: var(--white);
        font-weight: 600;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -15px;
    }

    .margin {
        width: 35px;
        height: 50px;
    }

    .mobile-menu {
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 15px;
        position: relative;
        z-index: 11;
    }

    .mobile-menu i {
        font-size: 2em;
        color: var(--brand)
    }

    @media (max-width: 1023px) {
        .logo {
            flex: 0 0 100px;
            padding-right: 20px;
        }

        .row {
            flex-wrap: nowrap;
        }

        .deposit i {
            margin-right: 0;
        }
        .active-symbols .item{
            padding: 10px 0 10px 20px;
            min-width: 60px;
        }
    }

    .mobile .left-row {
        display: none !important;
    }

    .mobile .header {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        flex: 0 0 36px;
        overflow: hidden;
        padding: 3px 15px;
    }

    .mobile .aspin-design.header {
        flex: 0 0 100px;
    }

    .mobile .logo {
        flex: 0 0 100px;
        padding-right: 20px;
    }

    .mobile .logo img {
        height: auto !important;
        width: 100px;
    }

    .mobile .header .row:first-child {
        margin-bottom: 10px;
    }

    .mobile .layout {
        margin: 0 5px
    }

    .mobile .row {
        justify-content: space-between;
    }

    .mobile .mobile-menu {
        display: flex;
    }

    .mobile .deposit {
        display: none;
    }

    .mobile .circle {
        width: 25px;
        height: 25px;
    }

    .mobile .balance .col {
        flex-direction: row;
        align-items: center;
    }

    .mobile .balance .acc-type {
        margin-right: 20px;
    }

    .mobile .balance .val {
        font-size: 14px;
    }

    .noscroll {
        overflow-x: auto;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .active-symbols {
        margin-left: 20px;
    }

    .plate {
        margin: 0 20px 0 0;
        height: 50px;
    }

    .plate img {
        height: 100%;
    }

</style>
