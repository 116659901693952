<template>
    <div class="modal-wrapper" :class="{hide: !modals.canselPosition}" v-on:click.stop="hideModal">
        <div class="modal ElevationDark__3" v-on:click.stop>
            <div class="title">
                <span class="title__large">{{tokens.widgetOrders.cancelOrder}}</span>
            </div>
            <span class="warning body__medium">
                    {{ tokens.closePositionModal.areYouSure }}
                    #{{ modalClosePosition.activeOrderId }}?
            </span>
            <div
                v-if="activeOrder.symbol"
                class="symbol-content"
            >
                <div class="symbol-info">
                    <span
                        class="symbol"
                        :class="`${'symbol-' + activeOrder.symbol.toUpperCase().replace('.', '-')}`"
                    />
                    <span class="body__large alias">
                        {{
                            symbols[activeOrder.symbol] ? symbols[activeOrder.symbol].alias : ''
                        }}
                    </span>
                </div>
                <div class="price-info">
                    <span class="body__large profit">
                        {{
                            activeOrder.profit.toLocaleString(undefined, {
                                minimumFractionDigits: symbols[activeOrder.symbol] ? symbols[activeOrder.symbol].precision : 3,
                                maximumFractionDigits: symbols[activeOrder.symbol] ? symbols[activeOrder.symbol].precision : 3
                            })
                        }}
                    </span>
                    <BasePositionDirection :type="activeOrder.type"/>
                </div>
            </div>
            <div class="row actions">
                <BaseButton
                    :button-style="'text-primary'"
                    :button-text="tokens.newDesign.cancel"
                    @click.native="hideModal()"
                />
                <BaseButton
                    :button-style="'filled-primary'"
                    :button-text="tokens.newDesign.confirm"
                    @click.native="closePosition(modalClosePosition.activeOrderId, modalClosePosition.index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from '@/store/mutations.type'
import {CFD_ORDER_CLOSE_REQUEST} from '@/store/actions.type'
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue";
import BasePositionDirection from "@/new-design/shared/ui/panels/BasePositionDirection.vue";

export default {
    name: "CancelPositionModal",
    components: {BasePositionDirection, BaseButton},
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                canselPosition: false
            })
        },
        closePosition(id, index) {
            this.$store.dispatch(CFD_ORDER_CLOSE_REQUEST, {
                id,
                index,
                account_id: this.activeAccount.id
            })

            this.hideModal()
        },
    },
    computed: {
        ...mapGetters(['modals', 'activeAccount', 'tokens', 'symbols', 'modalClosePosition', 'closeOrderLoader']),
        activeOrder() {
            let currentOrder = Object.values(this.activeAccount.orders).find(order => order.id === this.modalClosePosition.activeOrderId);

            return currentOrder || {
                alias: '',
                type: 0,
                volume: 0
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--System-Surface3);
    border-radius: var(--Corner-small);
    z-index: 1100;
}

.title {
    padding: 24px;
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warning {
    padding: 0 24px;
    color: var(--System-OnSurfaceVariant);
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
    gap: 8px;
}

.symbol-content {
    padding: 24px 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price-info,
.symbol-info {
    display: flex;
    align-items: center;
}

.price-info {
    gap: 10px;
}

.symbol-info {
    gap: 12px;
}

.symbol {
    zoom: 0.4;
}

.alias,
.profit {
    color: var(--System-OnSurface);
}

.actions {
    justify-content: flex-end;
    padding: 24px;
    margin-top: 24px;
    border-top: 1px solid var(--System-OutlineVariant);
}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}

@keyframes l2 {
    to {
        transform: rotate(1turn)
    }
}

</style>
