<template>
    <div class="switch-to-banner">
        <div class="left">
            <BaseButtonSmall
                :button-text="!value ? tokens.newDesign.switchToOldDesignButton : tokens.newDesign.switchToNewDesignButton"
                :button-style="'secondary-fixed'"
                @click.native="switchDesign"
            />
            <span class="message">
                {{ !value ? tokens.newDesign.switchToNewDesignMessage : tokens.newDesign.switchToOldDesignMessage }}
            </span>
        </div>
        <BaseIconButton
            :button-style="'secondary-fixed'"
            @click.native="close()"
        >
            <template #icon>
                <svg class="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M4.26683 12.6667L3.3335 11.7334L7.06683 8.00004L3.3335 4.26671L4.26683 3.33337L8.00016 7.06671L11.7335 3.33337L12.6668 4.26671L8.9335 8.00004L12.6668 11.7334L11.7335 12.6667L8.00016 8.93337L4.26683 12.6667Z"
                    />
                </svg>
            </template>
        </BaseIconButton>
    </div>
</template>

<script>
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue"
import {mapGetters} from "vuex";

export default {
    name: "BaseSwitchToBanner",
    components: {BaseIconButton, BaseButtonSmall},
    props: {
        currentDesign: {
            type: String,
        },
        value: {
            type: Boolean
        }
    },
    computed: {
      ...mapGetters(['tokens'])
    },
    methods: {
        switchDesign() {
            this.$emit('input', !this.value)
            this.$emit('update', !this.value)
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.switch-to-banner {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--System-SecondaryFixed);
    background: linear-gradient(90deg, rgba(6, 27, 62, 0.70) 0%, rgba(44, 38, 79, 0.70) 48%, rgba(0, 45, 56, 0.70) 100%), var(--System-Surface5);
}

.message {
    margin-left: 20px;
}

.left {
    display: flex;
    align-items: center;
}

.close {
    cursor: pointer;
}

.cursor path,
.close path {
    fill: var(--System-SecondaryFixed);
}

button {
    padding: 0;
}

@media (max-width: 479px) {
    .message {
        display: none;
    }
}
</style>
