<template>
    <div class="modal-wrapper" :class="{ hide: !modals.forgotPassword }">
        <div class="modal" :class="{ hide: modals.otp }">
            <div class="greeting-title">
                <span class="title__medium">{{tokens.newDesign.welcomeToTradingPlatform}}</span>
                <i class="material-icons" v-on:click="hideModal">close</i>
            </div>
            <div class="title-wrapper">
                <BaseModalTitle>
                    <template #left>
                        <div class="modal-title-left">
                            <i class="material-icons">forward_to_inbox</i>
                            <span class="title__medium">{{ tokens.newDesign.passwordRecovery }}</span>
                        </div>
                    </template>
                    <template #right>
                        <div class="modal-title-right">
                            <span class="label__medium">{{ tokens.loginModal.dontHaveAccount }}</span>
                            <BaseButtonSmall
                                :buttonText="tokens.newDesign.registration"
                                :buttonStyle="'tonal-secondary'"
                                @click.native="goToRegisterPage" />
                        </div>
                    </template>
                </BaseModalTitle>
            </div>
            <p class="modal-desc label__largeprominent">{{ tokens.forgotPasswordModal.submitEmail }}</p>
            <div class="modal-box">
                <BaseInput
                    v-model="email"
                    :title="'Email'"
                    name="email"
                    autocomplete="off"
                    :placeholder="tokens.loginModal.enterEmail" @keyup.native.enter="restorePassword" />
            </div>
            <div class="modal-footer">
                <BaseButton
                    :buttonStyle="'filled-primary'"
                    :buttonText="tokens.forgotPasswordModal.submit"
                    @click.native="restorePassword"
                    @keydown.native.enter="restorePassword" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { MODAL } from "@/store/mutations.type"
import { PASSWORD_RESTORE } from "@/store/actions.type"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton";
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall";
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle.vue";

export default {
    name: "ForgotPasswordModal",
    components: {
        BaseInput, BaseButton, BaseModalTitle, BaseButtonSmall
    },
    data() {
        return {
            email: '',
        }
    },
    methods: {
        hideModal() {
            this.email = ''
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        },
        restorePassword() {
            this.$store.dispatch(PASSWORD_RESTORE, {
                email: this.email.trim()
            })
            this.$store.commit(MODAL, {
                forgotPassword: false
            })
        },
        goToRegisterPage() {
            window.location.href = '/register';
        },
    },
    computed: {
        ...mapGetters(['tokens', 'modals'])
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 25px 28px;
    z-index: 1100;
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    background: var(--System-Surface1);
    position: relative;
}

.greeting-title {
    color: var(--System-OnSurfaceVariant);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: -34px;
    left: 0
}

.greeting-title i {
    cursor: pointer;
}

.title-wrapper {
    margin-bottom: 34px;
}

.modal-title-left {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-title-left i {
    font-size: 18px;
}

.modal-title-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.modal-desc {
    margin-bottom: 16px;
}

.modal-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 40px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-footer button {
    margin-left: auto;
}

@media(max-width: 768px){
    .modal{
        margin-inline: 32px;
    }
}
</style>
