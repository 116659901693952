<template>
    <footer class="footer">
        <div class="container">
            <div class="left">
                <span class="body__extrasmall avaliable">{{tokens.newDesign.availableFullTime}} | </span>
                <span class="email body__extrasmall">{{ supportEmail }}</span>
            </div>
            <div class="right">
                <div class="row app-download" v-if="varNames.APPLICATION_DOWNLOAD_LINK">
                    <a :href="varNames.APPLICATION_DOWNLOAD_LINK" target="_blank">
                        <BaseIconButton>
                            <template #icon>
                                <i class="material-symbols-outlined">cloud_download</i>
                            </template>
                        </BaseIconButton>
                    </a>
                </div>
                <div class="row live-chat" :class="{blinking: unreadMessageCounter !== '0' && supportUnread}"
                     v-if="user.enable_chat && !newChat " @click="showCabinetPage('support')">
                    <BaseIconButton>
                        <template #icon>
                            <i class="material-symbols-outlined">mail_outline</i>
                        </template>
                    </BaseIconButton>
                    <span>{{
                            tokens.footer.liveChat.toUpperCase()
                        }} {{
                            unreadMessageCounter === '0' || !supportUnread ? '' : `(${unreadMessageCounter})`
                        }}</span>
                </div>
                <div class="row live-chat" :class="{blinking: unreadMessageCounter !== '0' && supportUnread}"
                     v-else-if="user.enable_chat && newChat" @click="showSupportModal">
                    <BaseIconButton>
                        <template #icon>
                            <i class="material-symbols-outlined">mail_outline</i>
                        </template>
                    </BaseIconButton>
                    <span>{{
                            tokens.footer.liveChat.toUpperCase()
                        }} {{
                            unreadMessageCounter === '0' || !supportUnread ? '' : `(${unreadMessageCounter})`
                        }}</span>
                </div>
                <BaseIconButton @click.native="toggleMute">
                    <template #icon>
                        <i class="material-symbols-outlined">{{ mute ? 'volume_off' : 'volume_up' }}</i>
                    </template>
                </BaseIconButton>
                <BaseIconButton @click.native="showCabinetPage('settings')">
                    <template #icon>
                        <i class="material-symbols-outlined">settings</i>
                    </template>
                </BaseIconButton>
                <span class="nomobile"><span class="text body__extrasmall">{{
                        tokens.footer.currentTime
                    }} |</span> <span class="time">{{ time }}</span></span>
                <BaseIconButton
                    @click.native="fullscreen"
                >
                    <template #icon>
                        <i class="material-symbols-outlined">fullscreen</i>
                    </template>
                </BaseIconButton>
            </div>
        </div>
    </footer>
</template>

<script>
import {NEW_CHAT, SCHEDULE, SUPPORT_EMAIL} from "@/common/config"
import {isMobile, requestFullScreen} from "@/common/helpers"
import {MODAL, MODAL_FULLSCREEN, MUTE} from "@/store/mutations.type"
import {mapGetters} from "vuex"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue";

export default {
    name: "TheFooter",
    components: {BaseIconButton},
    mounted() {
        setInterval(() => {
            const date = new Date()
            const day = String(date.getDate()).padStart(2, '0')
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const year = date.getFullYear()
            const hours = String(date.getHours()).padStart(2, '0')
            const minutes = String(date.getMinutes()).padStart(2, '0')
            const seconds = String(date.getSeconds()).padStart(2, '0')
            this.time = `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`

        }, 1000)
    },
    data() {
        return {
            supportEmail: SUPPORT_EMAIL,
            schedule: SCHEDULE,
            time: '',
            isMobile: isMobile(),
            unreadMessageCounter: '0',
            supportModalIsOpen: false,
            newChat: NEW_CHAT,
        }
    },
    methods: {
        fullscreen() {
            requestFullScreen(document.body)
        },
        toggleMute() {
            this.$store.commit(MUTE, !this.mute)
        },
        showCabinetPage(page) {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL, {
                hamburgerMenu: false
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
        },
        showSupportModal() {
            if (this.isMobile) {
                this.showCabinetPage('support')
            } else {
                this.supportModalIsOpen = !this.supportModalIsOpen
            }
        },
        closeSupportModal() {
            this.supportModalIsOpen = false
        }

    },
    computed: {
        ...mapGetters(['mute', 'user', 'tokens', 'supportMessages', 'modalFullscreen', 'supportUnread', 'variables', 'staticBase']),
        varNames() {
            return this.variables
        }
    },
    watch: {
        supportMessages: {
            deep: true,
            handler(newVal) {
                if (newVal) {
                    let messageCounter = '0'
                    for (const n in newVal) {
                        if ((!newVal[n].time_read || newVal[n].time_read === 0)) {
                            ++messageCounter
                            if (messageCounter > 100) {
                                this.$set(this, 'unreadMessageCounter', '99+')
                                break
                            }
                        }
                    }

                    this.$set(this, 'unreadMessageCounter', messageCounter)
                }
            }
        }
    }
}
</script>

<style scoped>
.footer {
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--System-Surface);
    border-top: 1px solid var(--System-OutlineVariant);
    position: relative;
    padding: 0 12px;
}

.container {
    padding: 9px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.live-chat {
    align-items: center;
    gap: 2px;
}

i {
    font-size: 16px;
}

.avaliable,
.text {
    color: var(--System-Outline);
}

.time {
    color: var(--System-OnSurfaceVariant);
}

.email {
    color: var(--System-OnSurfaceVariant);
}

@media (max-width: 767px) {
    .footer {
        display: none;
    }
}
</style>
