import { render, staticRenderFns } from "./OrdersTable.vue?vue&type=template&id=18f9bcaa&scoped=true"
import script from "./OrdersTable.vue?vue&type=script&lang=js"
export * from "./OrdersTable.vue?vue&type=script&lang=js"
import style0 from "./OrdersTable.vue?vue&type=style&index=0&id=18f9bcaa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f9bcaa",
  null
  
)

export default component.exports